/*******************************
             Global
*******************************/

/*--------------
       Page
  ---------------*/

/*
    Cores:
        #a78656 - Dourado
        #eccda4 - Dourado claro
*/

#main .review.card {
    width: 100% !important;
    background-color: #F9FAFB;

}

#main .ui.inverted.menu {
    background-color: #a78656;
}

.no-padding {
    padding: 0 !important;
}

.refresh {
    margin-left: 1em !important;
    background-color: #a78656 !important;
}

.search.button {
    background-color: #a78656 !important;
    color: white;
}

.pusher {
    position: relative;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    min-height: 100vh;
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
    z-index: 10;
}

ul.list {
    list-style-type: disc;
}

ul.list li {
    list-style-position: outside;
}

p {
    margin: 1em 0em;
}

p:first-child {
    margin-top: 0em;
}

p:last-child {
    margin-bottom: 0em;
}

/*----------------
        Footer
  -----------------*/

#main>.pusher>.footer {
    margin: 0em;
    border-radius: 0em;
    padding: 4em 0em 4em 0em;
}

/*--------------
       Sidebar
  ---------------*/

#main .ui.logout.button {
    background-color: #a78656;
    color: #fff;
    border: 1px solid #fff;
}

#main .ui.logout.button:hover {
    background-color: #eccda4;
    color: black;
}


#main .ui.sidebar.menu .item {
    will-change: transform;
}

#main .sidebar.menu .logo.image,
#main .ui.menu .logo.image {
    width: 100%;
}

#main #toc>* {
    will-change: transform;
}

#main #toc> :last-child {
    padding-bottom: 2em;
}

#main #toc>.ui.menu {
    max-height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

/* Sidebar Header */
#main #toc .inverted.header {
    font-size: 1em;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 0.5em;
}

#main #toc a.inverted.header.active,
#main #toc a.inverted.header:hover {
    color: rgba(255, 255, 255, 1);
}

#main #toc .menu .active.item {
    color: #FFF;
}

/*--------------
     Page Headers
  ---------------*/

#main .masthead.segment {
    position: relative;
    z-index: 3;
    margin: 0em;
    min-height: 185px;
    padding: 3em 0em;
    background-color: #FFFFFF;
    border-bottom: 1px solid #DDDDDD;
    box-shadow: none;
}

#main .masthead .introduction {
    display: block;
}

#main .masthead .introduction:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

#main .masthead.segment h1 {
    margin: 0em;
}

#main .masthead iframe.github {
    width: 101px !important;
}

#main .masthead iframe {
    margin-left: 5px;
    float: right;
}

/* Align with buttons */
#main .masthead .main.menu {
    margin-top: -2px;
    min-height: 0em;
}

#main .masthead .main.menu>.item {
    padding-top: 0.785714em;
    padding-bottom: 0.785714em;
}

/*--------------
     Fixed Menu
  ---------------*/

#main .main.menu .title.item b {
    margin-right: 0.5em;
}

#main .fixed.main.menu .launch.item {
    display: block;
}

#main .fixed.main.menu .search .icon {
    opacity: 0.9;
}

/*--------------
     Pagination
  ---------------*/

.ui.borderless.pagination.menu {
    border: none !important;
    box-shadow: none !important;
}

/*******************************
          Alternate Layouts
  *******************************/

/*----------------
     Wide Layout
  -----------------*/

#main.wide .main.container {
    max-width: 1200px !important;
}

/*----------------
     Basic Layout
  -----------------*/

#main.basic .fixed.menu>.container,
#main.basic .main.container,
#main.basic .masthead>.container {
    position: relative;
    width: 700px !important;
    left: 0px;
    margin-right: 4em !important;
}

#main .main.container .introduction p,
#main .main.container .tab>p,
#main .main.container .section>p,
#main .main.container>p {
    font-size: 16px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
}

/* Link Styles */
#main .main.container>p a,
#main .main.container .section>p a {
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: top;
    z-index: 0;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
    outline: none;
}

#main .main.container>p a:before,
#main .main.container .section>p a:before {
    position: absolute;
    top: auto;
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #4183C4;
    content: '';
    -webkit-transition: all 0.2s;
    -webkit-backface-visibility: hidden;
    transition: all 0.2s;
    backface-visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
}

#main .main.container>p a:hover:before,
#main .main.container>p a:focus:before,
#main .main.container .section>p a:hover:before,
#main .main.container .section>p a:focus:before {
    background-color: #2C76BF;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

/*----------------
     Minimal Layout
  -----------------*/

/* Always Hidden Sidebar */
#main.minimal.basic>.launch.button {
    display: block;
}

#main.minimal.basic .full.height>.toc {
    display: none;
}

#main.minimal.basic .full.height>.article {
    margin-left: 0px;
}

#main.minimal.basic .fixed.menu>.container,
#main.minimal.basic .main.container,
#main.minimal.basic .masthead>.container,
#main.minimal.basic .footer>.container {
    position: relative;
    width: 700px !important;
    left: 0px;
    margin-left: auto !important;
    margin-right: auto !important;
}

#main.minimal>.pusher>.footer {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@media only screen and (max-width: 820px) {

    #main.minimal.basic .fixed.menu>.container,
    #main.minimal.basic .main.container,
    #main.minimal.basic .masthead>.container,
    #main.minimal.basic .footer>.container {
        width: auto !important;
        margin-left: 1em !important;
        margin-right: 1em !important;
    }
}

/*----------------
       Examples
  -----------------*/

#main .section {
    margin: 0em 0em;
    padding: 2em 0em !important;
    position: relative;
    -webkit-tap-highlight-color: transparent;
}

/*******************************
           Sidebar Layout
  *******************************/

#main>.pusher>.full.height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

#main:not(.site)>.pusher>.full.height {
    background-color: #FFFFFF;
}

/* page layout */
#main .full.height>.toc {
    position: fixed;
    z-index: 1;
    background-color: #1b1c1d;
    width: 250px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

#main .full.height>.toc .ui.menu {
    border-radius: 0;
    border-width: 0 1px 0 0;
    box-shadow: none;
    margin: 0;
    width: inherit;
    overflow: hidden;
    will-change: transform;
    max-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

#main .article {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 0px;
    margin-left: 250px;
}

#main .full.height>.toc .ui.menu::-webkit-scrollbar {
    -webkit-appearance: none;
}

#main .full.height>.toc .ui.menu::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0);
}

#main .full.height>.toc .ui.menu::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0);
}

#main .full.height>.toc .ui.menu:hover::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
}

#main .full.height>.toc .ui.menu:hover::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.25);
}

#main>.pusher>.footer,
#main .article>.footer {
    border-top: 1px solid #DDDDDD;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08) inset;
    background-color: #FAFAFA;
    padding-top: 2em;
}

/*******************************
        Definition Container
  *******************************/

#main .fixed.menu>.container,
#main .main.container,
#main .masthead>.container {
    position: relative;
}

#main .main.container {
    padding: 2em 0em 7em;
}

/*******************************
            Responsive
  *******************************/

/* Defaults */
#main .masthead>.container,
#main .main.container,
#main .fixed.menu>.container {
    margin-left: 3em !important;
    margin-right: 3em !important;
    width: auto !important;
    /* max-width: 960px !important; */
}

/* #main .masthead>.container {
    margin-right: 387px !important;
}

#main .main.container {
    margin-right: 387px !important;
} */

.overflow {
    overflow-x: scroll !important;
}

/* Squish Content (Just For Small Computers)
prev: max-width: 1272 */
@media only screen and (max-width: 1521px) {

    /* Resize TOC  */
    #main .full.height>.toc {
        width: 200px;
    }

    #main .article {
        margin-left: 200px;
    }

    #main .masthead>.container,
    #main .main.container,
    #main .fixed.menu>.container {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }

    /* Resize Main Container */
    /* #main .main.container {
        margin-right: 318px !important;
    } */

    #main .fixed.column {
        width: 250px;
        margin-left: 2.5em;
    }

}

/* Hide Top Bar */
@media only screen and (min-width: 1145px) {
    #main .fixed.main.menu {
        display: none;
    }
}

/* Remove Fixed Sidebar /  Show Top Bar */
@media only screen and (max-width: 1144px) {

    /* Hide Fixed Sidebar */
    #main .full.height>.toc {
        display: none;
    }

    #main .full.height>.article {
        margin-left: 0px;
    }

    /* Hide Fixed Launch Button */
    #main .fixed.launch.button {
        display: none !important;
    }

    /* Additional Padding for Fixed Menu */
    #main .masthead.segment {
        padding-top: 5em;
    }

    /* Less Left Padding */
    #main .masthead>.container,
    #main .main.container,
    #main .fixed.menu>.container {
        margin-left: 2em !important;
    }

    /* 100% Menu Width */
    #main .fixed.menu>.container {
        margin-left: 0em !important;
        margin-right: 0em !important;
        width: 100% !important;
        max-width: none !important;
    }

    /* Remove Bug Reports */
    #main .main.menu .bug.item {
        display: none;
    }

    /* Hide Music *]/
    #main .main.menu .music.item {
      display: none;
    }
  
    /* Space For Header Over Examples */
    #main .fixed.column .sticky {
        padding-top: 2em;
    }

}

@media only screen and (max-width: 992px) {

    /* Fluid Containers */
    #main .masthead>.container,
    #main .main.container,
    #main .fixed.menu>.container {
        margin-left: 1em !important;
        margin-right: 1em !important;
        max-width: none !important;
    }

    /* Fixed menu */
    #main .fixed.menu>.container {
        margin-left: 0em !important;
        margin-right: 0em !important;
        width: 100% !important;
        max-width: none !important;
    }
}


@media only screen and (max-width: 768px) {

    #main .masthead>.container:first-child {
        flex-direction: column;
    }

    #main .masthead.segment {
        padding-bottom: 0em;
    }

    #main.button-page .section>.button,
    #main.button-page .section .html>.button {
        margin-bottom: 8px;
    }

    #main .stackable.grid .ui.vertical.divider {
        display: none;
    }

    /* Add Sticky Footers */
    body.pushable>.pusher {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    body.pushable>.pusher>.full.height {
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    }

    /* Let Buttons Vertically Stack */
    #main .masthead .button {
        margin-bottom: 0.75em;
    }

    #main .masthead .main.menu {
        display: none;
    }

    #main .masthead .main.menu+.main.menu {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    /* Hide Sitemap */
    #main .pusher>.footer .grid .three.column {
        display: none !important;
    }
}