/* .ui.anuncio.header {
    font-size: 2.1em;
} */

.ui.anuncio.sub.header {
    font-size: 1.5em;
    margin-bottom: 1em;
}

.feature.list .item {
    padding: 0.5em 0;
}

.text {
    font-size: 1.1em;
}