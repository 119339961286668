/*
    Cores:
        #a78656 - Dourado
        #eccda4 - Dourado claro
*/

/* * {
    font-family: 'Poppins', sans-serif;
} */

.golden {
    color: #a78656;
}

.bold {
    font-weight: 800;
}

/*  +----------------+
    |    MASTHEAD    |
    +----------------+  */

@media only screen and (max-width: 768px) {
    /* .masthead .logo.image {
        width: 198px !important;
        height: 68px !important;
    } */

    .masthead.menuprincipal {
        display: none !important;
    }

    .gap {
        height: 100px;
    }
}

@media only screen and (min-width: 769px) {
    .menulateral {
        display: none !important;
    }
}

.menulateral {
    background-color: #a78656 !important;
    border-bottom: 5px solid #cdae7f !important;
    z-index: 9999;
}

.menu-lateral-aberto {
    background-color: #a78656 !important;
}

.hamburger.icon.item.link {
    border-width: 0 !important;
    height: auto;
    width: 67px;
}

.menulateral .logo {
    width: 197px !important;
    height: 67px !important;
    margin: 1em;
}

.masthead .logo {
    width: 260px !important;
    height: 89px !important;
}

.masthead .secondary.menu {
    border-bottom: none !important;
    flex-wrap: wrap;
}

.masthead .ui.container .ui.secondary.menu .active.item {
    color: #a78656;
    border-color: #a78656;
    background-color: inherit;
}

.masthead .ui.container .ui.secondary.menu .item:hover {
    color: #a78656;
    border-color: #a78656;
    background-color: inherit;
}

.masthead .ui.container .ui.secondary.menu .active.item:hover {
    color: #313131;
}


.masthead .ui.container .ui.secondary.menu .item {
    align-self: auto !important;
}

.masthead.top {
    padding: 1.5em 0em;
    background: rgb(236, 205, 164) !important;
    background: linear-gradient(105deg, rgba(236, 205, 164, 1) 0%, rgba(167, 134, 86, 1) 30%, rgba(140, 113, 73, 1) 50%, rgba(167, 134, 86, 1) 70%, rgba(236, 205, 164, 1) 100%) !important;
}

.masthead .ui.menu .right.item .ui.button {
    margin-left: 0.5em;
    box-shadow: none;
}

.masthead .ui.menu .right.item .ui.button:hover {
    margin-left: 0.5em;
    box-shadow: none;
    background: #cdae7f !important;
    color: #a78656;
}

.masthead {
    border-bottom: 5px solid #cdae7f;
}

.masthead .stackable.grid {
    margin-top: 10em;
    height: 100%;
}

/*  +--------------+
    |    BANNER    |
    +--------------+  */

.maxBanner.banner {
    width: 100%;
    height: auto;
    min-height: 70px !important;
    min-width: 210px !important;
    max-height: 96px !important;
    max-width: 800px !important
        /* 100px !important; */
}

.featured.banner {
    min-height: 280px !important;
    min-width: 174px !important;
    max-height: 280px !important;
    max-width: 174px !important;
}

.miniBanner.banner {
    min-height: 70px !important;
    min-width: 174px !important;
    max-height: 70px !important;
    max-width: 174px !important;
}

.banner h1.ui.header {
    font-size: 3em;
    font-weight: normal;
}

.masthead.banner {
    min-height: 500px;
    padding: 1em 0em;
    background: #eccda4 url("/public/banner.png") no-repeat center / cover !important;
}

.masthead.secondary.banner {
    position: relative;
    min-height: 300px !important;
    padding: 1em 0em;
    background-color: #eccda4 !important;
    background-image: url("/public/banner2.jfif") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 0% 30% !important;
}

.masthead.secondary.banner::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0px -100px 50px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: inset 0px -100x 50px 0px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px -100px 50px 0px rgba(0, 0, 0, 0.25);
}

.masthead.secondary.banner .header {
    color: white;
    text-shadow: 0px 0px 10px rgba(150, 150, 150, 1);
}

/*  +------------------+
    |    PAGINATION    |
    +------------------+  */

.table.label {
    padding: 0.5em;
    border-radius: 1em;
}

.green.table.label {
    background: #21ba45 !important;
    color: #fff;
}

.red.table.label {
    background: #db2828 !important;
    color: #fff;
}

.yellow.table.label {
    background: #fbbd08 !important;
    color: #fff;
}

.ui.borderless.pagination.menu {
    border: none !important;
    box-shadow: none !important;
}

/*  +---------------+
    |    SECTION    |
    +---------------+  */

.ui.basic.simple.segment {
    padding: none !important;
    margin: none !important;
}

.guiaDeEmpresas.basic.segment {
    margin-bottom: 2em !important;
}

.guiaDeEmpresas.section {
    border: none !important;
    border-bottom: none !important;
}

.publicidade {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

@media only screen and (max-width: 768px) {

    /* .anuncio.card {
        display: block;
        flex-direction: row !important;
    } */

    /* .anuncio.card img {
        margin: 0 !important;
        width: 150px !important;
        height: 150px !important;
    } */

    /* .anuncio.card h3,
    .anuncio.card div {
        margin: auto !important;
        padding: 0 1em;
        vertical-align: middle !important;
    } */
}

.anuncio.card {
    padding: 1em !important;
    width: auto !important;
}

.anuncio.card:hover {
    cursor: default !important;
}

.anuncio.card h3,
.anuncio.card p {
    color: #313131;
}

.anuncio.card .header {
    font-size: 1.3em;
    color: #000000D9;
}

.certified.title {
    font-size: 0.8em !important;
    font-weight: 500 !important;
    color: #666 !important;
}

.locations-card-text,
.categories-card-text {
    margin-top: 0.5em;
}

.ui.tag.category.label {
    margin-right: 1em;
    margin-top: 0.33em;
}

/* .categories-card-text .category-text {
    color: #fff;
    background-color: #a78656;
    border-radius: 2em;
    padding: 0.25em;
} */

.ui.ribbon.label.sale {
    margin-bottom: 1em !important;
}

.anuncio.content {
    border: 0 !important;
}

.ui.vertical.section.segment {
    border-bottom: none !important;
}

.section .ui.header {
    margin-bottom: 2em;
}

.container .horizontal.header.divider {
    color: #a78656;
}

.small.section {
    padding-top: 0 !important;
    padding-left: 0em !important;
    padding-right: 0em !important;
    padding-bottom: 6em !important;
}

.section {
    padding: 6em 0em !important;
}

.featured.column h3,
.category.title h3 {
    z-index: 10;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.featured.column h3 {
    font-size: 1.87em !important;
}

.category.title h3 {
    font-size: 1.5em !important;
}

.featured.column h4,
.category.title h4 {
    z-index: 10;
    font-weight: 100;
    font-size: 1.23em !important;
    color: #a78656;
    margin-top: 0px;
    white-space: nowrap;
}

.featured.column p {
    font-size: 1.23em !important;
}

.category.title {
    height: 33%;
    width: 66%;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    position: absolute;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
}

.category.title::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.85);
    /* background: inherit;
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.75);
    filter: blur(25px);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px); */
    z-index: -1;
}

@media only screen and (min-width: 768px) {
    /* .category.card {
        min-height: 356px !important;
        max-height: 356px !important;
    }

    .travel.image {
        min-height: 450px !important;
        max-height: 450px !important;
    } */
}

.category.content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.streched.image {
    width: 100% !important;
    height: 250px !important;
}

.travel .header {
    text-transform: uppercase;
    font-size: 1.87em;
}

/*  +------------------------+
    |    BANNER & SECTION    |
    +------------------------+  */

.masthead.banner .button,
.section.segment .button {
    background-color: #a78656;
    color: #fff;
    border: 1px solid #fff;
}

.masthead.banner .button:hover,
.section.segment .button:hover {
    background-color: #eccda4;
    color: #313131;
}

.ui.fluid.input .ui.search.button {
    margin-left: 28px;
}

/*  +--------------+
    |    FOOTER    |
    +--------------+  */

.footer.segment .header {
    font-size: 1.1em;
}

.footer.segment .link.icon {
    color: #fff;
}

.footer.segment .link.icon:hover {
    color: #a78656;
}

.footer.segment button {
    background-color: #fff;
    color: #313131;
}

.footer.segment button:hover {
    background-color: #a78656;
    color: #313131;
}

.ui.inverted.vertical.footer.segment {
    padding: 5em 0em;
}

.footer.logo {
    max-width: 197px !important;
    max-height: 67px !important;
}

/*  +------------+
    |    FORM    |
    +------------+  */

.no-outline {
    border: 1px solid #FFF !important;
}

.filter.header {
    font-size: 1.1rem !important;
}

.filter.header i {
    color: rgb(99, 99, 99) !important;
}

.error.label {
    margin-top: 0.5em;
    color: #9f3a38;

}

.image.input.label {
    display: block;
    margin: 0 0 0.28571429rem 0;
    color: rgba(0, 0, 0, .87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
}

.send.file.button {
    color: #313131;
    background-color: #ddd;
}