.ui.materia.header {
    font-size: 2.1em;
    margin-bottom: 0 !important;
}
.divGeral{
    display: flex;
    flex-direction: row;
}

.divFiltros{
    display: flex;
    flex-direction: column;
    width: 20%
}

.divAnuncio{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 60%
}

.divPubli{
    display: flex;
    flex-direction: column;
    width: 20%
}