.ui.right.floated.basic.segment {
    margin-right: 0 !important;
    padding: 0;
}

.signup .ui.button {
    background-color: #a78656;
    color: #fff;
    border: 1px solid #fff;
}

.signup .ui.button:hover {
    background-color: #eccda4;
    color: #313131;
}

.ui.secondary.button {
    background-color: #fff;
    color: #a78656;
    border: 1px solid #a78656;
}

.ui.secondary.button:hover {
    background-color: #eccda4;
    color: #313131;
}

.login.segment {
    width: 680px;
}

.sign.up.button {
    background-color: #fff;
    color: #a78656;
    border: 1px solid #a78656;
    margin-left: 1em;
}

.sign.up.button:hover {
    background-color: #eccda4;
    color: #313131;
}

.forgot.password {
    margin-top: 20px;
    text-align: center;
    text-decoration: underline;
    font-size: 0.85em;
    color: #a78656;
    cursor: pointer;
}

.forgot.password:hover {
    color: #eccda4;
    text-decoration: underline;
}